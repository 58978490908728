<template>
  <button
    v-if="variant === 'primary'"
    :class="
      twMerge(
        'group cursor-pointer disabled:pointer-events-none',
        $attrs.class as any,
      )
    "
    :disabled="isDisabled"
  >
    <div class="relative z-0 p-4">
      <div
        class="relative z-0 -translate-y-4 rounded-full border border-black px-9 py-6 transition-all group-hover:-translate-y-2 group-active:translate-y-0 group-disabled:translate-y-0 group-disabled:border-inactive group-disabled:bg-inactive-bg"
        :class="[btnBgColor]"
      >
        <div
          class="relative z-0 text-center text-p1 font-bold uppercase text-navy group-disabled:text-inactive"
        >
          <slot />
        </div>

        <div class="absolute inset-0 -z-10 flex overflow-hidden rounded-full">
          <div
            class="grow -translate-x-full rounded-full transition-all group-hover:-translate-x-0 group-active:-translate-x-full"
            :class="[btnBgHoverColor]"
          />
        </div>
      </div>

      <div
        class="absolute bottom-4 left-4 right-4 top-4 -z-10 rounded-full bg-navy"
      />

      <div
        class="absolute bottom-0 left-0 right-0 top-5 -z-20 rounded-full"
        :class="[btnOuterShadowColor]"
      />
    </div>
  </button>

  <button
    v-else-if="variant === 'primary-flat'"
    :class="
      twMerge(
        'group relative z-0 cursor-pointer rounded-full border-navy px-9 py-3 shadow-sm disabled:pointer-events-none disabled:border disabled:bg-inactive-bg disabled:shadow-none',
        $attrs.class as any,
        btnBgColor,
      )
    "
  >
    <div
      class="relative text-center text-p1 font-bold text-navy group-disabled:text-inactive"
    >
      <slot />
    </div>

    <div class="absolute inset-0 -z-10 flex overflow-hidden rounded-full">
      <div
        class="grow -translate-x-full rounded-full transition-all group-hover:-translate-x-0 group-active:-translate-x-full"
        :class="[btnBgHoverColor]"
      />
    </div>
  </button>

  <!-- TODO: REMOVE 'primary-join' variants with 'primary-flat' in app -->
  <button
    v-else-if="variant === 'primary-join'"
    :class="
      twMerge(
        'group relative z-0 cursor-pointer rounded-full border-navy px-9 py-3 shadow-sm disabled:pointer-events-none disabled:border disabled:bg-inactive-bg disabled:shadow-none',
        $attrs.class as any,
        btnBgColor,
      )
    "
  >
    <div
      class="relative text-center text-p1 font-bold uppercase text-navy group-disabled:text-inactive"
    >
      <slot />
    </div>

    <div class="absolute inset-0 -z-10 flex overflow-hidden rounded-full">
      <div
        class="grow -translate-x-full rounded-full transition-all group-hover:-translate-x-0 group-active:-translate-x-full"
        :class="[btnBgHoverColor]"
      />
    </div>
  </button>

  <button
    v-else-if="variant === 'secondary'"
    :class="
      twMerge(
        'group relative z-0 cursor-pointer rounded-full border-navy px-6 py-4 shadow-sm disabled:pointer-events-none disabled:border disabled:bg-inactive-bg disabled:shadow-none',
        btnBgColor,
        $attrs.class as any,
      )
    "
  >
    <div
      class="relative text-center text-p2 font-bold text-navy group-disabled:text-inactive"
    >
      <slot />
    </div>

    <div class="absolute inset-0 -z-10 flex overflow-hidden rounded-full">
      <div
        class="grow -translate-x-full rounded-full transition-all group-hover:-translate-x-0 group-active:-translate-x-full"
        :class="[btnBgHoverColor]"
      />
    </div>
  </button>

  <button
    v-else-if="variant === 'icon'"
    :class="
      twMerge(
        'flex h-[32px] w-[32px] shrink-0 cursor-pointer items-center justify-center rounded-full bg-clean text-center text-p1 font-bold text-navy shadow-sm disabled:pointer-events-none disabled:text-inactive disabled:opacity-40 md:h-10 md:w-10',
        $attrs.class as any,
      )
    "
    :disabled="isDisabled"
  >
    <slot />
  </button>

  <NuxtLink
    v-else-if="variant === 'link'"
    :class="
      twMerge(
        'cursor-pointer p-2 text-center text-p1 font-bold shadow-[0_10px_0_-8px_rgba(0,0,0,1)] disabled:pointer-events-none disabled:text-inactive disabled:opacity-40',
        $attrs.class as any,
      )
    "
    :disabled="isDisabled"
  >
    <slot />
  </NuxtLink>

  <button
    v-else
    :class="
      twMerge(
        'texp-p1 cursor-pointer rounded-full border border-navy bg-clean px-4 py-2 text-center text-p1 font-bold text-navy active:bg-navy active:text-white disabled:pointer-events-none disabled:text-inactive',
        $attrs.class as any,
      )
    "
    :disabled="isDisabled"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
import { twMerge } from "tailwind-merge";
import type { ButtonHTMLAttributes } from "vue";

interface ButtonProps extends /* @vue-ignore */ Partial<ButtonHTMLAttributes> {
  variant?: string;
  bgColor?: string;
  bgHoverColor?: string;
  bgOuterShadowColor?: string;
}
const { variant, disabled, bgColor, bgHoverColor, bgOuterShadowColor } =
  defineProps<ButtonProps>();

const isDisabled = ref(disabled ?? false);

const btnBgColor = computed(() => {
  return bgColor
    ? `bg-${bgColor}`
    : variant === "secondary"
      ? "bg-clean"
      : "bg-yellow";
});

const btnBgHoverColor = computed(() => {
  return bgHoverColor ? `bg-${bgHoverColor}` : "bg-yellow-medium";
});

const btnOuterShadowColor = computed(() => {
  return bgOuterShadowColor ? bgOuterShadowColor : "bg-white";
});
</script>
