import { useAccessToken } from "~/domains/member/composables";

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const $api = $fetch.create({
    baseURL: config.public.apiURL,
    onRequest({ options }) {
      const token = useAccessToken();
      if (token.value) {
        options.headers = {
          Authorization: `Bearer ${token.value}`,
          Accept: "application/json",
          ...options.headers,
        };
      } else {
        options.headers = {
          Accept: "application/json",
          ...options.headers,
        }
      }
    },
  });

  return {
    provide: {
      api: $api,
    },
  };
});
