import { queryOptions } from "@tanstack/vue-query";
import camelize from "camelcase-keys";
import type { Campaign } from "~/lib/campaigns";

export const subscriptionQueries = {
  all: () => ["subscription"],
  lists: () => [...subscriptionQueries.all(), "list"],
  list: (params: MaybeRef<{ memberId: number }>) =>
    queryOptions({
      queryKey: [...subscriptionQueries.lists(), params],
      queryFn: async () => {
        const api = useApi();
        const p = toValue(params);
        const data = await api<ListResponse>(
          `/v1/members/${p.memberId}/subscriptions`,
          {
            method: "GET",
          },
        );

        return camelize(data, { deep: true });
      },
      staleTime: 1000 * 60 * 15,
    }),
  details: () => [...subscriptionQueries.all(), "detail"],
  detail: (params: MaybeRef<{ memberId: number; subscriptionId: number }>) => [
    ...subscriptionQueries.details(),
    params,
  ],
  paymentMethod: (
    params: MaybeRef<{ memberId: number; subscriptionId: number }>,
  ) =>
    queryOptions({
      queryKey: [...subscriptionQueries.detail(params), "paymentMethod"],
      queryFn: async () => {
        const api = useApi();
        const p = toValue(params);
        const data = await api<PaymentMethodResponse>(
          `/v1/members/${p.memberId}/subscriptions/${p.subscriptionId}/payment-method`,
          {
            method: "GET",
          },
        );

        return camelize(
          { last4: data.last4, type: data.type.replace("_", "-") },
          { deep: true },
        );
      },
      staleTime: 1000 * 60 * 15,
    }),
};

type ListResponse = {
  id: number;
  created_at: string;
  updated_at: string;
  member_id: number;
  status: "active" | "inactive" | "past_due";
  plan: string;
  amount: string;
  processing_fee: string;
  ddc_fee: string;
  tip: string;
  processor: "stripe" | "nmi" | "paypal" | "braintree";
  deleted_at: string | null;
  subscription_id: string;
  allocation: Record<Campaign, number | undefined>;
  past_due_date: string | null;
}[];

type PaymentMethodResponse = {
  last4: string;
  type: "card" | "google_pay" | "apple_pay";
};
