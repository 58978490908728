import { getUser } from "~/domains/auth/helpers";
import { useReferralCode } from "~/domains/signup/composables";

export default defineNuxtRouteMiddleware(async (to) => {
  const user = await getUser();
  if (user) {
    if (to.query.r !== user.referralCode) {
      return navigateTo(
        {
          ...to,
          query: { ...to.query, r: user.referralCode },
        },
        { replace: true },
      );
    }
  } else {
    const referralCode = useReferralCode();

    const r = to.query.r;
    if (!r || typeof r !== "string") {
      return;
    }

    referralCode.value = r;
  }
});
