import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxt) => {
  const app = nuxt.vueApp;
  const router = useRouter();

  Sentry.init({
    app,
    enabled: process.env.VERCEL_ENV === "production",
    dsn: "https://d61e80913cdf5ec18f38486935d22f0f@o558932.ingest.us.sentry.io/4507375432564736",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    tracesSampleRate: 0.1,
  });
});
