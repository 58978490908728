import { useQueryClient } from "@tanstack/vue-query";
import { memberQueries } from "../member/queries";

export async function getUser() {
  const queryClient = useQueryClient();
  try {
    const user = await queryClient.fetchQuery(memberQueries.self());

    return user;
  } catch (error) {
    return null;
  }
}
