import { useQuery } from "@tanstack/vue-query";
import { memberQueries } from "./queries";
import { useSetDatastoreKey } from "./mutations";

export const useAccessToken = () =>
  useCookie("access_token", {
    sameSite: "strict",
    secure: import.meta.env.PROD,
    path: "/",
  });

export const useSelf = () => {
  return useQuery(memberQueries.self());
};

export function usePreference<T>(
  params: MaybeRefOrGetter<{ key: string; defaultValue: T }>,
) {
  const { data: self } = useSelf();
  const memberId = computed(() => {
    if (!self.value) {
      throw new Error("Not logged in");
    }
    return self.value.id;
  });

  const { data: datastore } = useQuery(memberQueries.datastore(memberId));
  const { mutate } = useSetDatastoreKey(memberId);

  return computed({
    get: () => {
      const value = datastore.value?.[toValue(params).key] as T | undefined;
      return value ?? toValue(params).defaultValue;
    },
    set: (value: T) => {
      mutate({
        key: toValue(params).key,
        value,
      });
    },
  });
}
