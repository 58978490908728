import { queryOptions } from "@tanstack/vue-query";
import camelize from "camelcase-keys";
import type { Campaign } from "~/lib/campaigns";

export const transactionQueries = {
  all: () => ["transaction"],
  lists: () => [...transactionQueries.all(), "list"],
  list: (params: MaybeRef<{ memberId: number }>) =>
    queryOptions({
      queryKey: [...transactionQueries.lists(), params],
      queryFn: async () => {
        const api = useApi();
        const p = toValue(params);
        const data = await api<ListResponse>(
          `/v1/members/${p.memberId}/transactions`,
          {
            method: "GET",
          },
        );

        return camelize(data, { deep: true });
      },
      staleTime: 1000 * 60 * 15,
    }),
};

type ListResponse = {
  id: number;
  created_at: string;
  updated_at: string;
  member_id: number;
  subscription_id: number;
  amount: string;
  tip: string;
  processing_fee: string;
  ddc_fee: string;
  project_id: number | null;
  processed: 0 | 1;
  processor: "stripe" | "nmi" | "paypal" | "braintree";
  transaction_id: string;
  allocation: Record<Campaign, number | undefined>;
  activation_fee: string | null;
  receipt_id: string;
  refund_amount: string | null;
  refund_id: string | null;
  refund_status: string | null;
  has_refund: 0 | 1;
  has_dispute: 0 | 1;
  dispute_amount: string | null;
  dispute_status: string | null;
  dispute_id: string | null;
}[];
