import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_encoding@0.1.13_es_jku3xkkkqori22codjl52zyhry/node_modules/nuxt/dist/pages/runtime/validate.js";
import referral_45global from "/vercel/path0/middleware/referral.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_encoding@0.1.13_es_jku3xkkkqori22codjl52zyhry/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  referral_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authenticated: () => import("/vercel/path0/middleware/authenticated.ts")
}